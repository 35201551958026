import React from 'react';

const VkdMessageAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container">
        <p className="react-chatbot-kit-chat-bot-avatar-letter">V</p>
      </div>
    </div>
  );
};

export default VkdMessageAvatar;
