import {useEffect, useState,useCallback} from "react";
import { useSelector } from 'react-redux';
import './App.css';
import {  useLocation } from "react-router-dom";

import Loader from "./util/Loader";
import Home from "./Home";

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';


// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Grid from '@mui/material/Grid';

import axios from 'axios';

// defaultTheme
import themes from 'themes';

import Routes from 'routes';

function App() {

  //functions to manage the loading indicator
  const showModal = async () => {
    return setLoading(true);
  }

  const hideModal = async () => {
      return setLoading(false);
  }

  const[loading, setLoading] = useState(false);
  const customization = useSelector((state) => state.customization);
  const baseUri = process.env.REACT_APP_API_URL;
  const [ authorizedUser, setAuthorizedUser ] = useState(false);
  //const [ pageName, setPageName ] = useState('');
  let location = useLocation();
  let pageRoute = (location.pathname).split('/');
  //console.log(pageRoute);
  const pageName = pageRoute[1];
 console.log('PAGENAME',pageName);
  //setPageName(pageRoute[1]);

  let getAuthorizedUser = useCallback( async () => {
    //try to hit the queries endpoint, which requires authentication
    showModal();
    let endpoint = `${baseUri}/queries/`;
    try{
      let queries = await axios.get(endpoint,{withCredentials: true});
      console.log('QUERIES',queries);
      setAuthorizedUser(true);

    } catch(e){
      //console.log('NOT AUTHENTICATED');
      setAuthorizedUser(false);
    }

    hideModal();
  
  } , [baseUri])

  useEffect( () => {
    getAuthorizedUser();
  },[getAuthorizedUser]);


  return (

   <>
  <Loader open={loading} />

  { pageName === 'columnchart' || pageName === 'portalview' || pageName === 'dashboard' || pageName === 'vecap' || pageName === 'stackedcolumnchart' || pageName === 'linechart' 
      || pageName === 'vkd-assistant' || pageName === 'piechart' || pageName === 'materialdatagrid'  ? (

        <Routes /> 

      ) : (
    
        <>
        {authorizedUser ? (
            <>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                  <Routes /> 
                </NavigationScroll>
              </ThemeProvider>
            </StyledEngineProvider> 
            </>
              ) : (
            <>
             <Grid>
              <Grid item>

                <Home />

              </Grid>
            </Grid>
            </>
              )

      }
        
        </>

      )

  }


   

    </>

  );

  
}

export default App;
