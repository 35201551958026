import './Portal.css';
import PortalTopic from './PortalTopic';
import PortalIndicators from './PortalIndicators';
import React, {useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loader from "../util/Loader";

export default function PortalView(){

    const [categories, setCategories] = useState([]);
    const [categoryActive, setCategoryActive] = useState(-1);
    const [categoryActiveName, setCategoryActiveName] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [indicatorSubCategories, setIndicatorSubCategories] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [indicatorActive, setIndicatorActive] = useState(-1);
    const [chartTitle, setChartTitle] = useState('');
    const [chartTitle2, setChartTitle2] = useState('');
    const [chartLink, setChartLink] = useState('#');
    const [isIndicator, showIsIndicator] = useState(false);
    const baseApiUri = process.env.REACT_APP_API_URL;
    const[open, setOpen] = useState(false);
    const baseAppUri = process.env.REACT_APP_APP_URI;
    const { id } = useParams();

    //functions to manage the loading indicator
    const showModal = async () => {
        return setOpen(true);
    }
    
    const hideModal = async () => {
        return setOpen(false);
    }


   const ref = useRef(null);

   
    const showIndicator = async (indicator) => {

        console.log(`showing indicator for`, indicator);

        setIndicatorActive(indicator.id);
        showIsIndicator(true);
        //console.log(baseAppUri);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        console.log('BASE APP URI', baseAppUri);
        console.log('INDICATOR', indicator);
        //handle multiple charts for an indicator
        if(indicator.chart_url.indexOf('~') > -1){
            console.log('MUTIPLE CHARTS');
            let urls = indicator.chart_url.split('~');
            let titles = indicator.title.split('~');
            let src = baseAppUri+urls[0];
            let src2 = baseAppUri+urls[1];
            let title = titles[1];
            let title2 = titles[2];
            let link = indicator.link;
            setChartTitle(title);
            setChartTitle2(title2);
            setChartLink(link);
            document.getElementById("indicatorFrame").src = src; 
            document.getElementById("indicatorFrame2").src = src2; 
        } else {
            console.log('SINGLE CHART');
            let src = baseAppUri+indicator.chart_url;
            let title = indicator.title;
            let link = indicator.link;
            setChartTitle(title);
            setChartLink(link);
            document.getElementById("indicatorFrame").src = src; 
        }

        
        console.log('ACTIVE INDICATOR', indicatorActive);
   
             
    }

    const showSubcategory = async (category, id, topic) => {
        console.log('Portal topic', topic);
        console.log(`Portal showing subcategory for ${category}`);
        console.log('Portal sub categories', subCategories);
        console.log('Portal category clicked element', id);
        setCategoryActive(id);
        setCategoryActiveName(`/ ${category}`)
        //document.getElementById(elt);
        let filteredSubCategories = subCategories.filter(function (el) {
            //console.log(el);
            return el.topic === topic && el.category === id;
        }
        );
        console.log('FILTERED SUB CATEGORIES', filteredSubCategories);
        setIndicatorSubCategories(filteredSubCategories);
        
    }

    useEffect( () => {

        const getDashboard = async () => {

            const getCategories = async (id) => {

                let endpoint = `${baseApiUri}/portals/categories/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:categories:table`;
                let resp = await axios.get(endpoint);
                console.log('CAT', resp.data);
                return resp.data;
                      
            }
        

            const getTopics = async (id) => {
                showModal();
                let topicsEndpoint = `${baseApiUri}/portals/topics/${id}`;
                //let topicsEndpoint = `${baseApiUri}/table/table/dashboard:topics:table`;
                //let endpoint = id;
                let resp = await axios.get(topicsEndpoint);
                //console.log('TOPICS',resp.data);
                //setTopics(resp.data.rows);
                setTopics(resp.data);
                //return resp.data.rows.reverse();
                return resp.data;
            }

            const getIndicators = async (s) => {

                //console.log(isIndicator);
                let endpoint = `${baseApiUri}/portals/indicators/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:indicators:table`;
                let resp = await axios.get(endpoint);
                //for each of the indicators, add them to the proper subcategories
                /*
                let subCategoryIndicators = {};
                //console.log('SUB CATEGORIES', s);
                for(let i = 0; i < subCategories.length; i++){
                    let subcategory = subCategories[i];
                    //console.log('SUB CATEGORIES (Integrations)', subcategory);
                }
                */
                //console.log('SUB CAT INDICATORS',subCategoryIndicators);
                hideModal();
                return resp.data;
                
            }

            const getSubCategories = async (id) => {

                let endpoint = `${baseApiUri}/portals/subcategories/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:subcategories:table`;
                let resp = await axios.get(endpoint);
                //let s = resp.data;
                //put the subcategories into an object we can filter on
                return resp.data;
                
                //console.log('TOPICS',topics);
            }

            //get topics
            let t = await getTopics(id);
            console.log('TOPICS', t);
            setTopics(t);
            //get categories
            let c = await getCategories(id);
            /*
            c.sort(function(a, b) {
                var textA = a.Name.toUpperCase();
                var textB = b.Name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            */
            //console.log('CATEGORIES', c);
            setCategories(c);
            //get subcategories  
            let s = await getSubCategories(id);
           // console.log('SUB CATEGORIES', s);
            setSubCategories(s);
             //get subcategories  
             let i = await getIndicators(s);
             console.log('INDICATORS', i);
             setIndicators(i);
             
            //console.log('TOPICS',topics);          
           // console.log('CATEGORIES', topicCategories);
        }

        getDashboard();
    }, [isIndicator, baseApiUri,id])

    return (

        <Box sx={{padding: '20px'}}>
            <Loader open={open} />
                <div className='dashboard-container' id="topics" >
                    <Grid container spacing={2}>
                        <Grid item xs={4} className='topic-grid'>
                        {topics.map((t, index) => (
                            <div key={index}>
                            <div className='vecap-container-subheading vecap-bold'>{t.name}</div>
                            <div className="vecap-list">                     
                                <div>
                                    <PortalTopic categoryActive={categoryActive}  topic={t} categories={categories} showSubcategory={showSubcategory} key={index} /></div>                      
                            </div>
                            </div>
                        ))} 
                        </Grid>
                        <Grid item xs={8}>
                            <div className='indicator-heading'>Indicators {categoryActiveName}</div>
                            {indicatorSubCategories.map((i, index) => (
                                                       
                                    <PortalIndicators key={index} name={i.name} indicatorActive={indicatorActive} showIndicator={showIndicator} indicators={indicators} subcategory={i.id_subcategory} category={i.category} topic={i.topic} />                            

                            ))}
                        </Grid>
                    </Grid>

                    </div>

                   
                    <div ref={ref} className='dashboard-container' id="indicators" >

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='indicator-iframe-heading'><a  rel="noreferrer" target="_blank" className='indicator-iframe-heading-link' href={chartLink}>{chartTitle}</a></div>
                            <div>
                            <iframe 
                                className="indicator-frame"
                                id="indicatorFrame"                                
                                height="500" 
                                width="100%" 
                                title="Indicator"
                                >
                                </iframe>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='indicator-iframe-heading'><a  rel="noreferrer" target="_blank" className='indicator-iframe-heading-link' href={chartLink}>{chartTitle2}</a></div>
                            <div>
                            <iframe 
                                className="indicator-frame"
                                id="indicatorFrame2"                                
                                height="500" 
                                width="100%" 
                                title="Indicator"
                                >
                                </iframe>
                            </div>
                        </Grid>
                    </Grid>

                    </div>
                    
                    
            </Box>    

    );
}