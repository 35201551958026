import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const AssistantLoader = (props) => {

  return (
    <div>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.open}
      >
        <p style={{ fontFamily: 'DM Sans, sans-serif', marginRight: '20px' }}>Analyzing How Are Vermont's Young Children... </p>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default AssistantLoader;