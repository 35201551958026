import React, {useState, useEffect } from 'react'
import {  useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import '../dashboard/Dashboard.css';
//import Button from '@mui/material/Button';
import axios from 'axios'
import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import { DataGrid } from '@mui/x-data-grid';
//icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
//import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const baseApiUri = process.env.REACT_APP_API_URL;

export default function Portals(){

    const[loading, setLoading] = useState(false);
    //const[portals, setPortals] = useState();
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    let  columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
          field: 'name',
          headerName: 'Name',
          flex: 3,
          editable: true,
        },
        {
            field: 'edit',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <EditOutlinedIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/portal/${row.id}`)} />
              </strong>
            ),
          }
          
         
      ];

     // const handleClickOpen = (portalId) => {
       // console.log('OPEN CHART ID',portalId);
   // };

    useEffect( () => {
        
        async function loadPortals() {

            setLoading(true);
            //let endpoint = `${baseApiUri}/dashboard/check`;
            let endpoint = `${baseApiUri}/portals`;
            console.log(endpoint);
            let res = await axios.get(endpoint);
            console.log(res.data);
            //setPortals(res.data);
            setRows(res.data);
            setLoading(false);
    
        }

        loadPortals();
    } , [])

    return (
        <div style={{textAlign: "left"}}>
            <Loader open={loading} />
			<Container>
        <Box sx={{padding: '20px'}}>
        <div className="admin-main">
            <h1>Portals</h1>
        </div>

        <div>
            <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
                </Box>
            </div>
        
        </Box>
        </Container>
        </div>
    )


    
}