import React, {useEffect, useState } from 'react'

export default function PortalTopic(props){

    const [categories, setCategories] = useState([]);
    //const [topics, setTopics] = useState([])

    useEffect( () => {
        const getCategories = async () => {
        //console.log('------------>getting topic categories');
        //console.log('PROPS', props);

            let topicCategories = [];
            
            for(let i = 0; i < props.categories.length; i++){
                let category = props.categories[i];
                //console.log('CATEGORY',props.categoryActive);
                if(category.topic === props.topic.id_topic){
                    //console.log('ADDING');
                    topicCategories.push(category);
                }

            }
           
            setCategories(topicCategories);
        }
        getCategories();
    }, [props])

    //console.log('TOPIC', props.topic);
    // className={"topic-link" + (t.Cateogry === props.categoryActive ? '-active' : '')} 

    return (
        <div>
        {categories.map((t, index) => (
            <div  className={"topic-link" + (props.categoryActive === t.id ? '-active' : '')} 
            onClick={() => props.showSubcategory(t.name,t.id, props.topic.id_topic)} key={index}>{t.name}</div>
        ))}
        </div>
    )
}