import React, {useEffect, useState } from 'react'

export default function DashboardTopic(props){

    const [categories, setCategories] = useState([]);
    //const [topics, setTopics] = useState([])

    useEffect( () => {
        const getCategories = async () => {
            //console.log('------------>getting topic categories');

            let topicCategories = [];
            
            for(let i = 0; i < props.categories.length; i++){
                let category = props.categories[i];
                console.log('CATEGORY',props.categoryActive);
                if(category[props.topic.name] === 1){
                    //console.log('ADDING');
                    topicCategories.push(category);
                }

            }
           
            setCategories(topicCategories);
        }
        getCategories();
    }, [props])

    //console.log('TOPIC', props.topic);
    // className={"topic-link" + (t.Cateogry === props.categoryActive ? '-active' : '')} 

    return (
        <div>
        {categories.map((t, index) => (
            <div  className={"topic-link" + (props.categoryActive === t.id ? '-active' : '')} 
            onClick={() => props.showSubcategory(t.Category,t.id )} key={index}>{t.Category} {t.Label}</div>
        ))}
        </div>
    )
}