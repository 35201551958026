import React, {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import '../dashboard/Dashboard.css';
import Button from '@mui/material/Button';
import axios from 'axios'
import Container from '@mui/material/Container';
import Loader from "../util/Loader";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import PortalAddElement from './PortalAddElement';
import PortalAddIndicator from './PortalAddIndicator';
import PortalEditElement from './PortalEditElement';
import PortalDeleteElement from './PortalDeleteElement';
//import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

const baseApiUri = process.env.REACT_APP_API_URL;

export default function Portal(){

    //const apiRef = useTreeViewApiRef();

    const[loading, setLoading] = useState(false);
    const[currentElement, setCurrentElement] = useState({});
    const[currentElementId, setCurrentElementId] = useState({});
    const { id } = useParams();
    const[portal, setPortal] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openIndicator, setOpenIndicator] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    //const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseIndicator = () => setOpenIndicator(false);
    const handleCloseEdit = () => setOpenEdit(false);
    const handleCloseDelete = () => setOpenDelete(false);

    //for managing elements
    const[currentEltType, setCurrentEltType] = useState('');
    const[currentEltName, setCurrentEltName] = useState('');
    const[childEltName, setChildEltName] = useState('');
    const[categories, setCategories] = useState([]);
    const[subCategories, setSubCategories] = useState([]);
    const[indicators, setIndicators] = useState([]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };


      async function loadPortal(id){
        let endpoint = `${baseApiUri}/portals/${id}`;
        //console.log(endpoint);
        let res = await axios.get(endpoint,{withCredentials: true} );
        return res.data;
    }

    async function loadTopics(id){
        let endpoint = `${baseApiUri}/portals/topics/${id}`;
        //console.log(endpoint);
        let res = await axios.get(endpoint,{withCredentials: true} );
        return res.data;
    }

    async function loadCategories(id){
        let endpoint = `${baseApiUri}/portals/categories/${id}`;
        //console.log(endpoint);
        let res = await axios.get(endpoint,{withCredentials: true} );
        return res.data;
    }

    async function loadSubCategories(id){
        let endpoint = `${baseApiUri}/portals/subcategories/${id}`;
        //console.log(endpoint);
        let res = await axios.get(endpoint,{withCredentials: true} );
        return res.data;
    }

    async function loadIndicators(id){
        let endpoint = `${baseApiUri}/portals/indicators/${id}`;
        //console.log(endpoint);
        let res = await axios.get(endpoint,{withCredentials: true} );
        return res.data;
    }

    async function getIndicatorsByLevel(indicators,level){
        let levelIndicators = [];
        if(level === 't'){
            levelIndicators = indicators.filter((indicator) => indicator.category === -1 && indicator.subcategory === -1 && indicator.topic > 0);
            console.log('TOPIC LEVEL INDICATORS', levelIndicators);
        }
        if(level === 'c'){
            levelIndicators = indicators.filter((indicator) => indicator.category > 0 && indicator.subcategory === -1 && indicator.topic > 0);
            console.log('CATEOGORY LEVEL INDICATORS', levelIndicators);
        }
        if(level === 's'){
            levelIndicators = indicators.filter((indicator) => indicator.category > 0 && indicator.subcategory > 0 && indicator.topic > 0);
            console.log('SUB CATEOGORY LEVEL INDICATORS', levelIndicators);
        }
        //console.log('LEVEL INDICATORS', indicators);
        
        return levelIndicators;

    }

    async function reload() {

        setLoading(true);

        //console.log('ID', id);
        let p = await loadPortal(id);
        //console.log('PORTAL',p);
        let topics = await loadTopics(id);
        //console.log('TOPICS', topics);
        let categories = await loadCategories(id);
        //console.log('CATEGORIES',categories);
        setCategories(categories);
        let subcategories = await loadSubCategories(id);
        //console.log('SUBCATEGORIES',subcategories);
        setSubCategories(subcategories);
        let indicators = await loadIndicators(id);       
        //console.log('INDICATORS',indicators);
        setIndicators(indicators);
        setLoading(false);
        
        let portalTree = [];
        let portalObj = {};
        portalObj.label = p[0].name;
        portalObj.id = `p${p[0].id}`;
        portalObj.children = [];
        let portal = [];
        for(let t = 0; t < topics.length; t++){
            let topic = topics[t];
            let topicObj = {};
            topicObj.id = `p${id}_t${topic.id_topic}`;
            topicObj.label = topic.name;
            //load the topic categories
            let topicCategories = categories.filter((cat) => cat.topic === topic.id_topic);
            if(topicCategories.length > 0){
                topicObj.children = [];
            }
            //console.log('TOPIC CATEGORIES',topic.id_topic,topicCategories);
            for(let c = 0; c < topicCategories.length; c++){
                let cat = topicCategories[c];
                let catObj = {};
                catObj.id = `p${id}_t${topic.id_topic}_c${cat.id_category}`;
                catObj.label = cat.name;

                //load the subcategories
                let topicSubCategories = subcategories.filter((subcat) => (subcat.category === cat.id_category) && (subcat.topic === topic.id_topic) );
                if(topicSubCategories.length > 0){
                    catObj.children = [];
                }
                for(let s = 0; s < topicSubCategories.length; s++){
                    let subcat = topicSubCategories[s];
                    let subcatObj = {};
                    subcatObj.id =  `p${id}_t${topic.id_topic}_c${cat.id_category}_s${subcat.id_subcategory}`;
                    subcatObj.label = subcat.name;

                    //load the indicators
                    let topicIndicators = indicators.filter((indicator) => (indicator.category === cat.id_category) && (indicator.topic === topic.id_topic) && (indicator.subcategory === subcat.id_subcategory));
                    if(topicIndicators.length > 0){
                        subcatObj.children = [];
                    }
                    for(let i = 0; i < topicIndicators.length; i++){
                        let indicator = topicIndicators[i];
                        let indicatorObj = {};
                        indicatorObj.id = `p${id}_t${topic.id_topic}_c${cat.id_category}_s${subcat.id_subcategory}_i${indicator.id}`;
                        indicatorObj.label = indicator.title;
                        subcatObj.children.push(indicatorObj);
                    }

                    catObj.children.push(subcatObj);

                }

                let categoryIndicators = await getIndicatorsByLevel(indicators, 'c');
                for(let i = 0; i < categoryIndicators.length; i ++){
                    let indicator = categoryIndicators[i];
                    if(!catObj.hasOwnProperty('children')){
                        catObj.children = [];
                    }
                    let indObj = {};
                   // console.log('ADDING CATEGORY INDICATOR', `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`)
                    if((indicator.category === cat.id_category) && (indicator.topic === topic.id_topic)){
                        indObj.label = indicator.title;
                        indObj.id = `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`;
                        console.log('INDOBJ ID',indObj.id);
                        catObj.children.push(indObj);
                    }
                    
                }

                topicObj.children.push(catObj);

            }

            let topicIndicators = await getIndicatorsByLevel(indicators, 't');
            for(let i = 0; i < topicIndicators.length; i ++){
                let indicator = topicIndicators[i];
                if(!topicObj.hasOwnProperty('children')){
                    topicObj.children = [];
                }
                let indObj = {};
                //console.log('ADDING TOPIC INDICATOR', `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`)
                if(indicator.topic === topic.id_topic){
                    indObj.label = indicator.title;
                    indObj.id = `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`;
                    console.log('INDOBJ ID',indObj.id);
                    topicObj.children.push(indObj);
                }
                
            }
            
            //console.log('TOPIC OBJ',topicObj)
            //let 
            portal.push(topicObj);
        }
          //console.log('PORTAL', portal);
          portalObj.children = portal;
          portalTree.push(portalObj);
          setPortal(portalTree);

    }

    
    useEffect( () => {

        async function load() {

            setLoading(true);
    
            console.log('ID', id);
            let p = await loadPortal(id);
            console.log('PORTAL',p);
            let topics = await loadTopics(id);
            console.log('TOPICS', topics);
            let categories = await loadCategories(id);
            console.log('CATEGORIES',categories);
            setCategories(categories);
            let subcategories = await loadSubCategories(id);
            console.log('SUBCATEGORIES',subcategories);
            setSubCategories(subcategories);
            let indicators = await loadIndicators(id);       
            console.log('INDICATORS',indicators);
            setIndicators(indicators);
            setLoading(false);
            
           let portalTree = [];
            let portalObj = {};
            portalObj.label = p[0].name;
            portalObj.id = `p${p[0].id}`;
            portalObj.children = [];
            let portal = [];
            for(let t = 0; t < topics.length; t++){
                let topic = topics[t];
                let topicObj = {};
                topicObj.id = `p${id}_t${topic.id_topic}`;
                topicObj.label = topic.name;
                //load the topic categories
                let topicCategories = categories.filter((cat) => cat.topic === topic.id_topic);
                if(topicCategories.length > 0){
                    topicObj.children = [];
                }
                console.log('TOPIC CATEGORIES',topic.id_topic,topicCategories);
                for(let c = 0; c < topicCategories.length; c++){
                    let cat = topicCategories[c];
                    let catObj = {};
                    catObj.id = `p${id}_t${topic.id_topic}_c${cat.id_category}`;
                    catObj.label = cat.name;
    
                    //load the subcategories
                    let topicSubCategories = subcategories.filter((subcat) => (subcat.category === cat.id_category) && (subcat.topic === topic.id_topic) );
                    if(topicSubCategories.length > 0){
                        catObj.children = [];
                    }
                    for(let s = 0; s < topicSubCategories.length; s++){
                        let subcat = topicSubCategories[s];
                        let subcatObj = {};
                        subcatObj.id =  `p${id}_t${topic.id_topic}_c${cat.id_category}_s${subcat.id_subcategory}`;
                        subcatObj.label = subcat.name;
    
                        //load the indicators
                        let topicIndicators = indicators.filter((indicator) => (indicator.category === cat.id_category) && (indicator.topic === topic.id_topic) && (indicator.subcategory === subcat.id_subcategory));
                        if(topicIndicators.length > 0){
                            subcatObj.children = [];
                        }
                        for(let i = 0; i < topicIndicators.length; i++){
                            let indicator = topicIndicators[i];
                            let indicatorObj = {};
                            indicatorObj.id = `p${id}_t${topic.id_topic}_c${cat.id_category}_s${subcat.id_subcategory}_i${indicator.id}`;
                            console.log('ADDING INDICATOR', `p${id}_t${topic.id_topic}_c${cat.id_category}_s${subcat.id_subcategory}_i${indicator.id}`);
                            indicatorObj.label = indicator.title;
                            subcatObj.children.push(indicatorObj);
                        }

                        

    
                        catObj.children.push(subcatObj);
    
                    }


                    let categoryIndicators = await getIndicatorsByLevel(indicators, 'c');
                    for(let i = 0; i < categoryIndicators.length; i ++){
                        let indicator = categoryIndicators[i];
                        if(!catObj.hasOwnProperty('children')){
                            catObj.children = [];
                        }
                        let indObj = {};
                        console.log('ADDING CATEGORY INDICATOR', `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`)
                        if((indicator.category === cat.id_category) && (indicator.topic === topic.id_topic)){
                            indObj.label = indicator.title;
                            indObj.id = `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`;
                            console.log('INDOBJ ID',indObj.id);
                            catObj.children.push(indObj);
                        }
                        
                    }

    
                    topicObj.children.push(catObj);
                }
                //console.log('TOPIC OBJ',topicObj)
                //add any indicators to the portal
                let topicIndicators = await getIndicatorsByLevel(indicators, 't');
                for(let i = 0; i < topicIndicators.length; i ++){
                    let indicator = topicIndicators[i];
                    if(!topicObj.hasOwnProperty('children')){
                        topicObj.children = [];
                    }
                    let indObj = {};
                    console.log('ADDING TOPIC INDICATOR', `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`)
                    if(indicator.topic === topic.id_topic){
                        indObj.label = indicator.title;
                        indObj.id = `p${id}_t${indicator.topic}_c${indicator.category}_s${indicator.subcategory}_i${indicator.id}`;
                        console.log('INDOBJ ID',indObj.id);
                        topicObj.children.push(indObj);
                    }
                    
                }
                portal.push(topicObj);
            }
              console.log('PORTAL', portal);
              portalObj.children = portal;
              portalTree.push(portalObj);
              setPortal(portalTree);
    
        }

        load();
        
       
    },[id] )

    async function addElement(){
        console.log('CURRENT ELEMENT', currentElement);
        console.log('CURRENT ELEMENT TYPE',currentEltType);
        setOpen(true)
    }

    async function addIndicator(){
        console.log('CURRENT ELEMENT', currentElement);
        console.log('CURRENT ELEMENT TYPE',currentEltType);
        setOpenIndicator(true)
    }

    async function addElementClose(){
        setOpen(false)
    }

    async function addIndicatorClose(){
        setOpenIndicator(false)
    }

    async function onItemFocus(event, id, value){
        console.log(id);
        setCurrentElementId(id);
        let idElts = id.split('_');
        console.log(idElts);
        let eltId = -1;
        let eltType = '-1';
        let eltName = '';
        let childEltName = '';
        if(idElts.length === 1){
            //it's a topic
            eltId = idElts[0].replace('p','');
            eltType = 'p';
            eltName = 'portal'
            childEltName = 'topic';
        } else if (idElts.length === 2){
            //it's a category
            eltId = idElts[1].replace('t','');
            eltType = 't';
            eltName = 'topic'
            childEltName = 'category';
        } else if (idElts.length === 3){
            //it's a subcategory
            eltId = idElts[2].replace('c','');
            eltType = 'c';
            eltName = 'category'
            childEltName = 'subcategory';
        }  else if (idElts.length === 4){
            //it's a subcategory
            eltId = idElts[3].replace('s','');
            eltType = 's';
            eltName = 'subcategory'
            childEltName = 'indicator';
        } else {
            //it's an indicator
            eltId = idElts[4].replace('i','');
            eltType = 'i';
            eltName = 'indicator'
        }
        setCurrentEltType(eltType);
        setCurrentEltName(eltName);
        setChildEltName(childEltName)
        console.log(eltId);
        try{
            let endpoint = `${baseApiUri}/portals/element/${eltType}/${eltId}`;
            console.log(endpoint);
            let res = await axios.get(endpoint,{withCredentials: true} );
            console.log(res.data);
            setCurrentElement(res.data);
        } catch(e){}
    }

    async function edit(event){
        setOpenEdit(true);
        
    }
    async function deleteElement(event){
        setOpenDelete(true);
        
    }

    async function editClose(event){
        setOpenEdit(false);
        
    }
    async function deleteElementClose(event){
        setOpenDelete(false);
        
    }

    return (
        <div style={{textAlign: "left"}}>
            <Loader open={loading} />
			<Container>
        <Box sx={{padding: '20px'}}>
        <div style={{float:'right'}}>
        { currentEltType === 'p' &&<Button  style={{marginRight:'5px'}} variant="contained" onClick={addElement}>Add Topic</Button>}
        { currentEltType === 't' &&<Button  style={{marginRight:'5px'}}  variant="contained" onClick={addElement}>Add Category</Button>}
        { currentEltType === 'c' &&<Button  style={{marginRight:'5px'}}  variant="contained" onClick={addElement}>Add Subcategory</Button>}
        { currentEltType !== 'i' &&    <Button  style={{marginRight:'5px'}}  variant="contained" onClick={addIndicator}>Add Indicator</Button>}
        <Button  style={{marginRight:'5px'}}  variant="contained" onClick={edit}>Edit Selected</Button>
        <Button style={{ marginRight: '5px'}} variant="contained" onClick={deleteElement}>Delete</Button>
        </div>
        <div className="admin-main">
            <h3>Manage Portal</h3>
        </div>
        
       
        <RichTreeView 
        items={portal}
        onItemFocus={onItemFocus}
        />

        </Box>
        </Container>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-element-title"
        aria-describedby="modal-element-description"
      >
        <Box sx={style}>
          <Typography id="modal-element-title" variant="h4" component="h4">
            Manage portal topics, categories, subategories and indicators
          </Typography>
          <PortalAddElement 
          currentElement={currentElement} 
          currentElementId={currentElementId} 
          currentEltType={currentEltType}
          currentEltName={currentEltName} 
          childEltName={childEltName}  
          categories={categories} 
          subcategories={subCategories} 
          indicators={indicators} 
          reload={reload}
          addElementClose={addElementClose}
          addIndicatorClose={addIndicatorClose}
          editClose={editClose}
          deleteElementClose={deleteElementClose}
          ></PortalAddElement> 
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-delete-element-title"
        aria-describedby="modal-delete-element-description"
      >
        <Box sx={style}>
          <Typography id="modal-delete-element-title" variant="h4" component="h4">
            Delete portal topics, categories, subcategories and indicators
          </Typography>
          <PortalDeleteElement 
          currentElement={currentElement} 
          currentElementId={currentElementId} 
          currentEltType={currentEltType}
          currentEltName={currentEltName} 
          childEltName={childEltName}  
          categories={categories} 
          subcategories={subCategories} 
          indicators={indicators} 
          reload={reload}
          addElementClose={addElementClose}
          addIndicatorClose={addIndicatorClose}
          editClose={editClose}
          deleteElementClose={deleteElementClose}
          ></PortalDeleteElement> 
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-edit-element-title"
        aria-describedby="modal-edit-element-description"
      >
        <Box sx={style}>
          <Typography id="modal-edit-element-title" variant="h4" component="h4">
            Edit portal topics, categories, subcategories and indicators
          </Typography>
          <PortalEditElement 
          currentElement={currentElement} 
          currentElementId={currentElementId} 
          currentEltType={currentEltType}
          currentEltName={currentEltName} 
          childEltName={childEltName}  
          categories={categories} 
          subcategories={subCategories} 
          indicators={indicators} 
          reload={reload}
          addElementClose={addElementClose}
          addIndicatorClose={addIndicatorClose}
          editClose={editClose}
          deleteElementClose={deleteElementClose}
          ></PortalEditElement> 
        </Box>
      </Modal>

      <Modal
        open={openIndicator}
        onClose={handleCloseIndicator}
        aria-labelledby="modal-indicator-title"
        aria-describedby="modal-indicator-description"
      >
        <Box sx={style}>
          <Typography id="modal-indicator-title" variant="h4" component="h4">
            Manage portal indicators
          </Typography>
          <PortalAddIndicator 
          currentElement={currentElement} 
          currentElementId={currentElementId} 
          currentEltType={currentEltType}
          currentEltName={currentEltName} 
          childEltName={childEltName}  
          categories={categories} 
          subcategories={subCategories} 
          indicators={indicators}
          reload={reload}
          addElementClose={addElementClose}
          addIndicatorClose={addIndicatorClose}
          editClose={editClose}
          deleteElementClose={deleteElementClose}
          ></PortalAddIndicator> 
        </Box>
      </Modal>
        </div>
    )

}