
import { useEffect, useState } from "react";
import React from "react";
import './App.css';
// material-ui
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { gridSpacing } from 'store/constant';

const Home = () => {

  const [hostedUI, setHostedUI] = useState('');

  const initHome = async () => {

    console.log('initializing......')
    let hostedUi = process.env.REACT_APP_HOSTED_UI;
    console.log(hostedUi);
    setHostedUI(hostedUi);
    
  }

  useEffect( () => {
    initHome();
    
},[]);

    return (
<div className="App-login">
<Grid container spacing={gridSpacing}>

<Grid item xs={4} ></Grid>
     <Grid item xs={4} >    
      <Card sx={{ maxWidth: 375, padding: '20px' }}>
      <CardMedia
        sx={{ height: 170 }}
        image="/vtkids-logo-gray.png"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Administration
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Login to manage all aspects of data presented on www.vermontkidsdata.org
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
        size="small" 
        href={hostedUI}
        variant="outlined"
        >Login</Button>
      </CardActions>
    </Card>
    </Grid>   
    <Grid item xs={4} ></Grid>
    </Grid>
    </div>
      
    )
};

export default Home;

/*
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Chart />
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Deposits />
            </Paper>
          </Grid>
          
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Reports />
            </Paper>
          </Grid>
        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
      */