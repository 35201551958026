import React, {useState } from 'react'

import Box from '@mui/material/Box';
import './Dashboard.css';
import Button from '@mui/material/Button';
import axios from 'axios'
import Container from '@mui/material/Container';
import Loader from "../util/Loader";

const baseApiUri = process.env.REACT_APP_API_URL;

export default function DashboardAdmin(){

    const[loading, setLoading] = useState(false);
    const[topics, setTopics] = useState([]);
    const[categories, setCategories] = useState([]);
    const[subcategories, setSubcategories] = useState([]);
    const[indicators, setIndicators] = useState([]);
    const[topicsshow, setTopicsshow] = useState(false);
    const[categoriesshow, setCategorieshow] = useState(false);
    const[subcategoriesshow, setSubcategorieshow] = useState(false);
    const[indicatorsshow, setIndicatorsshow] = useState(false);

    async function checkHealth() {

        setLoading(true);

        let endpoint = `${baseApiUri}/dashboard/check`;
        console.log(endpoint);
        let res = await axios.get(endpoint);
        console.log(res.data);
        if(res.data.topics.hasOwnProperty("unusedrefs")){
            setTopics(res.data.topics.unusedRefs);
            setTopicsshow(true);
        } 
        if(res.data.categories.hasOwnProperty("unusedRefs")){
            setCategories(res.data.categories.unusedRefs);
            setCategorieshow(true);
        }
        if(res.data.subcategories.hasOwnProperty("unusedRefs")){
            setSubcategories(res.data.subcategories.unusedRefs);
            setSubcategorieshow(true);
        }
        if(res.data.indicators.hasOwnProperty("columnsNotFound")){
            setIndicators(res.data.indicators.columnsNotFound);
            setIndicatorsshow(true);
        }
        
        

        setLoading(false);

    }

    return (
        <div style={{textAlign: "left"}}>
            <Loader open={loading} />
			<Container>
        <Box sx={{padding: '20px'}}>
        <div className="admin-main">
            <h1>Dashboard Admin</h1>
        </div>
        <div>
            <Button variant="contained" className="App-icon-spacing" onClick={checkHealth}>Dashboard Health Check</Button>
        </div>

         <div class="dashboard-health-section">
        { topicsshow ? (
            <div><h3>Topics with no categories</h3></div>
        ) :
            (
            <div></div>
            )
        } 
        <ul>
        {topics.map((topic) => (
            <li>{topic}</li>
        ))}
        </ul>
        </div>

        <div class="dashboard-health-section">
        { categoriesshow ? (
            <div><h3>Top Level Categories that have no subcategories</h3></div>
        ) :
            (
            <div></div>
            )
        } 
        <ul>
        {categories.map((category) => (
            <li>{category}</li>
        ))}
        </ul>
        </div>

        <div class="dashboard-health-section">
        { subcategoriesshow ? (
            <div><h3>Missing Sub Categories that are referenced by indicators</h3></div>
        ) :
            (
            <div></div>
            )
        } 
        <ul>
        {subcategories.map((category) => (
            <li>{category}</li>
        ))}
        </ul>
        </div>

        <div class="dashboard-health-section">
        { indicatorsshow ? (
            <div><h3>Indicators that have no associated subcategory</h3></div>
        ) :
            (
            <div></div>
            )
        } 
        <ul>
        {indicators.map((indicator) => (
            <li>{indicator}</li>
        ))}
        </ul>
        </div>

        </Box>
        </Container>
        </div>
    )

}