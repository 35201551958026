//import Highcharts from 'highcharts';
/* eslint no-eval: 0 */ 

async function applyConfig(options, config, chartType = null){

    console.log('SETTING CONFIG',config);
    
    let yAxisType = 'percent';

    try{
        yAxisType = config?.yAxis?.type;
    } catch(e){
        console.log(e);
    }
    if( yAxisType === null ||  yAxisType === '' || (typeof  yAxisType === 'undefined')){
        yAxisType = 'percent';
    }

    console.log('YAXIS TYPE',yAxisType);

    if(yAxisType === 'number'){
        options.plotOptions.series.dataLabels.format = '{point.y:,.0f}';
        options.yAxis.labels.format = '{value:,.0f}';
    }

    //let colors = config?.colors;
    options.colors = ["#009D8F",
    "#FCC633",
    "#E60F55",
    "#9B51E0",
    "#0693E3",
    "#0C6755",
    "#6ABF4B",
    "#ED7330"
    ];

    let tooltip = config?.tooltip;
    if(tooltip === null || tooltip === '' || (typeof tooltip === 'undefined')){
        if(yAxisType === 'percent'){
            let numPlaces = 1;
            console.log('THIS PCT',this);
            tooltip = `this.series.name +' <b>' + this.x +'</b>: <b>' + (this.y).toFixed(${numPlaces}) + '%</b>'`;
            if(chartType === 'ColumnChart'){
                console.log('ColumnChart yAxis CONFIG');
                tooltip = `if(Number(this.y) < 1 ){ '**' } else { this.series.name +' <b>' + this.x +'</b>: <b>' + (this.y).toFixed(${numPlaces}) + '%</b>' }`;
            }
        } else {
            let numPlaces = 0;
            console.log(numPlaces);
            tooltip = `this.series.name +' <b>' + this.x +'</b>: <b>' + (this.y).toLocaleString() + '</b>'`;
        }
    }

    options.tooltip = {
        formatter: function () {
            return eval(tooltip);
        }
    };
    console.log('RETURNED CONFIG', options);
    return options;
    
};

const _applyConfig = applyConfig;
export { _applyConfig as applyConfig };


