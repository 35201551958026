import * as React from 'react';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios'

const baseApiUri = process.env.REACT_APP_API_URL;

export default function PortalAddElement(props) {

    const [selectedCategory, setSelectedCategory] = React.useState('');
    const [selectedSubCategory, setSelectedSubCategory] = React.useState('');

    console.log('PROPS CATEGORIES',props.categories);

    const handleCategorySelectChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSubCategorySelectChange = (event) => {
        setSelectedSubCategory(event.target.value);
    };

    async function addElement(){

        console.log('ADD PROPS',props);
        console.log('VALUE',value);
        console.log('ADDING: ', props.childEltName);
        console.log('ADDING TO: ',props.currentElement);
        console.log('ADDING TO ID: ', props.currentElementId);
        console.log('SELECTED CATEGORY', selectedCategory);
        console.log('SELECTED SUBCATEGORY', selectedSubCategory);

        //if a category or subcategory are selected, add the mapping
        if(selectedCategory !== null && selectedCategory !== '0' && selectedCategory !== ''){
            console.log('Add a category mapping');
            //add the category to the parent
            if(selectedCategory !== ''){
                try{
                    let payload = {
                        "type": props.childEltName,
                        "val": selectedCategory,
                        "parent": props.currentElementId
                    }
                    //let endpoint = `${baseApiUri}/portals/addmapping/${props.childEltName}/${selectedCategory}/${props.currentElementId}`;
                    let endpoint = `${baseApiUri}/portals/addmapping`;
                    let elt = await axios.post(endpoint, payload,{withCredentials: true} );
                    console.log(elt);
                } catch(e){}
            }
        } else if(selectedSubCategory !== null && selectedSubCategory !== '0' && selectedSubCategory !== ''){
            console.log('Add a subcategory mapping');
            let payload = {
                "type": props.childEltName,
                "id": selectedSubCategory,
                "parent": props.currentElementId
            }
            //add the subcategory to the parent
            if(selectedSubCategory !== ''){
                try{
                    //let endpoint = `${baseApiUri}/portals/addmapping/${props.childEltName}/${selectedSubCategory}/${props.currentElementId}`;
                    let endpoint = `${baseApiUri}/portals/addmapping`;
                    let elt = await axios.post(endpoint, payload, {withCredentials: true} );
                    console.log(elt);
                } catch(e){}
            }
        } else {
            console.log('add new element');
            if(value !== ''){
                try{
                    let payload = {
                        "type": props.childEltName,
                        "val": value,
                        "parent": props.currentElementId
                    }
                    //let endpoint = `${baseApiUri}/portals/addelement/${props.childEltName}/${value}/${props.currentElementId}`;
                    let endpoint = `${baseApiUri}/portals/addelement`;
                    let elt = await axios.post(endpoint, payload ,{withCredentials: true} );
                    console.log(elt);
                } catch(e){}
            }
            
            
        }
        
        props.reload();
        props.addElementClose();

    }

  const [value, setValue] = React.useState(0);
 //console.log('TAB PROPS', props);
  //let elt = props.currentElement[0];
  //console.log('ELT', elt);

  const handleTextFieldChange = (event) => {
    setValue(event.target.value);
  };


  return (
    <Box sx={{ width: '100%' }}>
     <div style={{margin:'20px'}}  >
        
        { props.currentEltType === 'p' &&
        <TextField  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-topic" label="add topic" variant="outlined" />
         }
         { props.currentEltType === 't' &&
         <>
         <TextField  style={{marginRight:'5px',marginTop:'5px'}} onChange={handleTextFieldChange}  id="id-category" label="add category" variant="outlined" />

            <Box sx={{ minWidth: 120,marginTop:'20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="category-select-label">Or Select Existing Category</InputLabel>
                    <Select
                    labelId="category-select"
                    id="category-select"
                    value={selectedCategory}
                    label="Category"
                    onChange={ handleCategorySelectChange}
                    >
                        <MenuItem key={"cat_0"} value='0'>
                                Select a category
                         </MenuItem>
                      {props.categories.map((option) => (
                            <MenuItem key={"cat_"+option.id} value={option.id_category}>
                                {option.name}
                            </MenuItem>
                        ))}   
                    
                    </Select>
                </FormControl>
                </Box></>
         }
         { props.currentEltType === 'c' &&
         <>
         <TextField  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-subcategory" label="add subcategory" variant="outlined" />
         <Box sx={{ minWidth: 120,marginTop:'20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="subcategory-select-label">Or Select Existing Sub Category</InputLabel>
                    <Select
                    labelId="subcategory-select"
                    id="subcategory-select"
                    value={selectedSubCategory}
                    label="Sub Category"
                    onChange={ handleSubCategorySelectChange}
                    >
                        <MenuItem key={"subcat_0"} value='0'>
                                Select a subcategory
                         </MenuItem>
                      {props.subcategories.map((option) => (
                            <MenuItem key={"subcat_"+option.id_subcategory} value={option.id_subcategory}>
                                {option.name}
                            </MenuItem>
                        ))}   
                    
                    </Select>
                </FormControl>
                </Box></>
          
          }
         <Button style={{marginTop:'20px'}}variant="contained" onClick={addElement}>Add</Button>
         
      </div>
    </Box>
  );
}