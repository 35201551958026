import React, {useEffect, useState, useCallback } from 'react'
import {  useNavigate } from "react-router-dom";
import axios from 'axios'
import '../Admin.css';
//import { useParams } from 'react-router-dom';
//Material UI components

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';

//import Box from '@mui/material/Box';
//import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
//icons
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadIcon from '@mui/icons-material/Upload';
import EditOutlinedIcon from '@mui/icons-material/FileDownload';
import Loader from "../util/Loader";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import CircularProgress from '@mui/material/CircularProgress';

/*
GET /dataset/backups/{dataset} - list backups for a dataset
GET /dataset/backups/{dataset}/{version} - download a backup version (might want to use it for "are you sure?" purposes)
POST /dataset/backups/{dataset}/{version}/revert - revert a dataset to a backup version
*/


function SimpleDialog(props) {
const { onClose, selectedValue, open } = props;

const handleClose = () => {
    onClose(selectedValue);
};

const handleConfirmClick = (value) => {
    onClose(value);
};

return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Delete Report</DialogTitle>
        <div>The report you selected is about to be permanently deleted.  Do you wish to continue?</div>
        <Button onClick={() => handleConfirmClick('yes')}>Yes</Button>  
        <Button onClick={() => handleConfirmClick('no')}>No</Button>        
    </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

async function createData(
    id,
    name,
    table,
    type
  ) {
    return { id, name, table, type };
  }

const baseUri = process.env.REACT_APP_API_URL;
  

export default function Datasets() {

    const [rows, setRows] = useState([]);
    const[loading, setLoading] = useState(false);
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('');

    const [open, setOpen] = useState(false);
    
    const [selectedValue, setSelectedValue] = useState('no');

    //functions to manage the loading indicator
    const showModal = async () => {
        return setLoading(true);
    }
    
    const hideModal = async () => {
        return setLoading(false);
    }

    const [editOpen, setEditOpen] = useState(false);
    const [refreshOpen, setRefreshOpen] = useState(false);
    const [uploadTypeName, setUploadTypeName] = useState('');
    const [uploadTypeType, setUploadTypeType] = useState('');
    const [revertProgress, setRevertProgress] = useState(false);
    const [revertMsg, setRevertMsg] = useState(false);

    const handleEditClose = (value) => {
        setEditOpen(false);
        let chartId = {selectedValue};
        let name = {uploadTypeName};
        console.log('CLOSING', chartId, name);

        console.log(chartId['selectedValue']);
        if(value === 'save'){
            updateDataset(chartId.selectedValue, name.uploadTypeName);
        }
        console.log(value);
    };

    
    const handleEditOpen = async (chartId, name, type) => {
        //setEditOpen(true);
        setSelectedValue(chartId);
        setUploadTypeName(name);
        setUploadTypeType(type);
        console.log('OPEN CHART ID',chartId);
        console.log('Edit NAME', name);
        console.log('TYPE', type);
        let url = `${baseUri}/download/${type}`;
        window.location.assign(url)
    };
    

    const handleClickOpen = (chartId) => {
        setOpen(true);
        setSelectedValue(chartId)
        console.log('OPEN CHART ID',chartId);
    };    
    const handleClose = (value) => {
        setOpen(false);
        let chartId = {selectedValue};
        console.log(chartId['selectedValue']);
        if(value === 'yes'){
            //deleteChart(chartId.selectedValue);
        }
        console.log(value);
    };

    const navigate = useNavigate();

    let getDatasets = useCallback( async () => {
        //console.log(queries);
        showModal();

        let rows = [];
        try{

            let uploadTypes = await axios.get(`${baseUri}/table/table/upload_types`);
            console.log('UPLOAD TYPES',uploadTypes);

            let uploadTypesArray = [];
            for(let i  = 0; i < uploadTypes.data.rows.length; i++){
                let t = uploadTypes.data.rows[i];
                uploadTypesArray.push(t.type);
            }
            //let q = JSON.parse(queries);           
            for(let i  = 0; i < uploadTypes.data.rows.length; i++){
                let query = uploadTypes.data.rows[i];
                //console.log(JSON.parse(query.metadata));
                try{
                    //console.log(metaData);
                    //let title = name != null ? name : 'NO TITLE';
                    //console.log(title);
                    rows.push( await createData(query.id, query.name, query.table, query.type) );

                    
                    setLoading(false);
                } catch(e){
                    //rows.push( await createData(query.id, 'NO TITLE') );
                    setLoading(false);
                }
                
            }
        } catch(e){
            console.log('ERROR LOADING CHARTS', e.message);
            //return redirect("/");
        }


        //console.log(rows);
        setRows(rows);
        hideModal();
    } , [])


    useEffect( () => {
        getDatasets();
        
    },[getDatasets]);

    let updateDataset = async (e) => {

        console.log('UPDATING', selectedValue, uploadTypeType);

        try{let baseUri = process.env.REACT_APP_API_URL;
            let endpoint = `${baseUri}/dataset/${uploadTypeType}`;
            let data = {
                "name": uploadTypeName
            }
            console.log(endpoint,data);
    
            let update = await axios.post(endpoint, data, {withCredentials: true});
    
            console.log(update);
            console.log(rows);
            let newRows = rows;
            for(let i = 0; i < newRows.length; i++){
                let row = newRows[i];
                if(row.id.toString() === selectedValue.toString()){
                    row.name = uploadTypeName;
                    console.log('setting ', row.id, uploadTypeName);
                    setRows(newRows);
                }
            }
            

        } catch(e){ console.log(e); }
        
		
        
        //let res = await axios.delete(endpoint,{withCredentials: true});
        //console.log(res.data);
       

    }

    let  columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
            editable: true,
            renderEditCell: (params) => {
                return (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={params.value}
                    fullWidth
                    onChange={(e) => {
                      // Update the value in the cell
                      const updatedValue = e.target.value;
                      params.api.setEditCellValue({
                        id: params.id,
                        field: params.field,
                        value: updatedValue,
                      });
                      setUploadTypeName(updatedValue);
                      // Calling function to update the state and call api
                      //handleRowEdit('test', 'test');
                    }}
                   
                  />
                );
              },
            },
         
        {
          field: 'table',
          headerName: 'Table',
          flex: 3,
          editable: false
        },
        {
            field: 'download',
            headerName: 'Download',
            renderCell: ({ row }) => (
              <strong>
                 <EditOutlinedIcon className="App-icon-spacing App-icon-cursor" onClick={() => handleEditOpen(`${row.id}`,`${row.name}`,`${row.type}`)} />
              </strong>
            ),
          },
        {
            field: 'upload',
            headerName: 'Upload',
            renderCell: ({ row }) => (
              <strong>
                <UploadIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/fileupload/${row.id}`)} />
              </strong>
            ),
            },
        {
            field: 'refresh',
            headerName: 'Revert',
            renderCell: ({ row }) => (
              <strong>
                 <RefreshIcon className="App-icon-spacing App-icon-cursor"  onClick={() => handleRefreshOpen(`${row.id}`,`${row.name}`,`${row.type}`)} />
              </strong>
            ),
          }
          ,
        {
            field: 'delete',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <DeleteOutlineIcon className="App-icon-spacing App-icon-cursor" onClick={() => handleClickOpen(`${row.id}`)} />
              </strong>
            ),
          }
         
      ];

      const handleNameChange = (event) => {
		setUploadTypeName(event.target.value);
	};

    const handleEditConfirmClick = (action) => {
        setEditOpen(false);
        handleEditClose (action);
    };

    const handleRefreshConfirmClick = (action) => {
        
        handleRefreshClose (action);
    };

    const selectVersion = (event) => {
        console.log(event.target.value);
        setSelectedVersion(event.target.value)
    };

    const handleRefreshClose = async (value) => {

        console.log(value);
        console.log(selectedVersion);
        if(value !== 'cancel'){
            try{
                setRevertProgress(true);
                let endpoint = `${baseUri}/dataset/backups/${uploadTypeType}/${selectedVersion}/revert`;
                let resp = await axios.post(endpoint, {},{withCredentials: true}  );
                console.log(endpoint);
                console.log(resp.data);
                setRevertProgress(false);
                setRevertMsg(true);
    
            } catch(e){
    
            }
        } else {
            setRevertMsg(false);
            setRefreshOpen(false);
        }
        

    };

    const handleRefreshOpen = async (chartId, name, type) => {
        setRefreshOpen(true);
        setSelectedValue(chartId);
        setUploadTypeName(name);
        setUploadTypeType(type);
        console.log('OPEN CHART ID',chartId);
        console.log('NAME', name);
        console.log('TYPE', type);
        
        //get the backups for the name
        let endpoint = `${baseUri}/dataset/backups/${type}`;
        console.log(endpoint);
        try{
            let resp = await axios.get(endpoint,{withCredentials: true} );
            console.log(resp.data);
            setVersions(resp.data.versions);
        } catch(e){
            console.log(e);
        }
    };

    let handleRowEdit = async (params, event) => {

        console.log(params);
        console.log(uploadTypeName);
        try{let baseUri = process.env.REACT_APP_API_URL;
            let endpoint = `${baseUri}/dataset/${params.row.type}`;
            let data = {
                "name": uploadTypeName
            }
            console.log(endpoint,data);
    
            let update = await axios.post(endpoint, data, {withCredentials: true}); 
            console.log(update.data);        

        } catch(e){ console.log(e); }
        

    };

    return (
        <div>
            <Loader open={loading} />
            <Container>
            <h3>Datasets</h3>
            <div>
            <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onCellEditStop={handleRowEdit}
                    
                />
                </Box>
            </div>

            <div>
            </div>
           
            </Container>
            <SimpleDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
        />
         <Dialog open={editOpen}>
            <DialogTitle>Edit Dataset</DialogTitle>
            <div className="admin-dialog">
            <TextField 
						id="uploadTypeName" 
						label="Dataset Name" 
						variant="outlined" 
						value={uploadTypeName}
						name="uploadTypeName"
						onChange={handleNameChange} 
                        className="admin-dialog-text"
					/>
            </div>
            <Button onClick={() => handleEditConfirmClick('save')}>Save</Button>  
            <Button onClick={() => handleEditConfirmClick('cancel')}>Cancel</Button>        
        </Dialog>

        <Dialog open={refreshOpen}>
            <DialogTitle className="modal-title">Revert Dataset</DialogTitle>
            <div className="admin-dialog">
            You are about to replace the existing dataset: {uploadTypeName}  with a previous version
            <br/><br/>
            <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Select a previous version</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={selectVersion}
            >
                 {versions.map((v, index) => (
                    <FormControlLabel value={v.version} control={<Radio />} label={v.version} key={index} />
                ))}

            </RadioGroup>
            </FormControl> 
            </div>
           
           <Container>
            <Button className="modal-button" variant="contained" onClick={() => handleRefreshConfirmClick('replace')}>Replace</Button>    
            <Button className="modal-button" variant="contained" onClick={() => handleRefreshConfirmClick('cancel')}>Close</Button> 
            {revertProgress && (
                <CircularProgress />
            )}
            {revertMsg && (
            <span>Dataset Reverted</span>
            )}
           </Container>
            
                   
        </Dialog>
        </div>
    );


}