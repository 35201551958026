import axios from 'axios';

const baseUri = process.env.REACT_APP_API_URL;

//helper function to make a protected API call - can be used to determine if a user is currently authenticated
export async function isAuthenticated() {

let endpoint = `${baseUri}/queries/`;
    console.log(endpoint);
    try{
      let queries = await axios.get(endpoint,{ withCredentials: true });
      console.log(queries);
      return true;
    } catch(e){
      console.log(e);
      return false;
    }

}