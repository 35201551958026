import './Census.css';
import React, {useState} from 'react';
import axios from "axios";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import Loader from "../util/Loader";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default function Census() {

    const [geography, setGeography] = useState('state');
    const [searchText, setSearchText] = useState('');
    const [tables, setTables] = useState([]);
    const [table, setTable] = useState('');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const[loading, setLoading] = useState(false);
    const [variable, setVariable] = useState([]);
    const [variables, setVariables] = useState([]);
    const [dataset, setDataset] = useState("acs/acs5");
    const [year, setYear] = useState('2022');

    const handleDatasetChange = (event) => {
        setDataset(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };
    const handleGeoChange = (event) => {
        setGeography(event.target.value);
    };

    const handleSearchText = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = async (event) => {
        setTable(event.target.value);
        //console.log(event.target.value);
        let vars = await axios.get(`https://api.vtkidsdata.org/codes/census/variables/`+event.target.value);
        //console.log('VARIABLES', vars.data);
        setVariables(vars.data.variables);
    };
    
    const handleVariableChange = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setVariable(value);
    };

    function prepareColumns(columns){
        let cols = [];
        for(let i = 0; i < columns.length; i++ ){
            let column = columns[i];
            column.field = column.id;
            column.headerName = column.label;
            column.flex = 1;
            cols.push(column);
        }
        return cols;
    }
    function prepareRows(rows){
        let r = [];
        console.log('preparing rows', rows);
        //rows need a unique ID to maintain React state
        for(let i = 0; i < rows.length; i++ ){
            let row = rows[i];
            row.id = i;
            r.push(row);
        }
        return r;
    }

    const searchTables = async () => {
        setLoading(true);
        let search = {searchText};
        //console.log('searching', search);
        //let response = await axios.get(`https://data.vermontkidsdata.org/v1/search_concepts/`+search.searchText);
        let response = await axios.get('https://api.vtkidsdata.org/codes/census/tables/search?concept='+search.searchText);
       //console.log(response.data);
        let tables = response.data.tables;
        //once we get the tables, search the variables
        //let response = await axios.get('https://jwzzquhd03.execute-api.us-east-1.amazonaws.com/prod/codes/census/tables/search?concept='+search.searchText);
        // let concept = 'POPULATION%20UNDER%2018%20YEARS%20BY%20AGE';
        let censusTable = response.data.tables[0].table;
        console.log('table', censusTable);
        let vars = await axios.get(`https://api.vtkidsdata.org/codes/census/variables/`+censusTable);
        //console.log('VARIABLES', vars.data);
        setTables(response.data.tables);
        setTable(tables[0].table);
        setVariables(vars.data.variables);
        setLoading(false);
    };

    const getCensusData = async () => {
        //console.log('getting data', {table});
        setLoading(true);
        //let table = 'B09001';
        console.log('SEARCH TABLE', {table});
        console.log('YEAR', {year});
        console.log('VARIABLES', String({variable}.variable));
        //B09001/bbf_region?year=2018&variables=B09001_003E
        let endpoint = {table}.table+'/'+{geography}.geography+'?dataset='+{dataset}.dataset+'&year='+{year}.year+'&variables='+String({variable}.variable);
        console.log(endpoint);
        let response = await axios.get(`https://api.vtkidsdata.org/table/census/`+endpoint);
        console.log(response.data);
        let columns = response.data.columns;
        columns = prepareColumns(columns);
        let rows = response.data.rows;
        rows = prepareRows(rows);
        setRows(rows);
        setColumns(columns);
        setLoading(false);

    };

return (
<div>
<Loader open={loading} />

<div>
    <div><h3>US Census Bureau</h3></div>
    <div>
        <div className="rowContainer">

            <div className="colContainer">
             
                <div>
                    <FormControl className="formControl" >
                        <InputLabel id="dataset-label">Dataset</InputLabel>
                        <Select
                            labelId="dataset-label"
                            label="Dataset"
                            id="dataset"
                            value={dataset}
                            onChange={handleDatasetChange}
                        >
                            <MenuItem value={'acs/acs1'}>American Community Survey (ACS) 1 Year</MenuItem>
                            <MenuItem value={'acs/acs3'}>American Community Survey (ACS) 3 Year</MenuItem>
                            <MenuItem value={'acs/acs5'}>American Community Survey (ACS) 5 Year</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl className="formControl">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            labelId="year-label"
                            label="Year"
                            id="year"
                            value={year}
                            onChange={handleYearChange}
                        >
                            <MenuItem value={'2022'}>2022</MenuItem>
                            <MenuItem value={'2021'}>2021</MenuItem>
                            <MenuItem value={'2020'}>2020</MenuItem>
                            <MenuItem value={'2019'}>2019</MenuItem>
                            <MenuItem value={'2018'}>2018</MenuItem>
                            <MenuItem value={'2017'}>2017</MenuItem>
                            <MenuItem value={'2016'}>2016</MenuItem>
                            <MenuItem value={'2015'}>2015</MenuItem>
                            <MenuItem value={'2014'}>2014</MenuItem>
                            <MenuItem value={'2013'}>2013</MenuItem>
                            <MenuItem value={'2012'}>2012</MenuItem>
                            <MenuItem value={'2011'}>2011</MenuItem>
                            <MenuItem value={'2010'}>2010</MenuItem>
                            <MenuItem value={'2009'}>2009</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <FormControl className="formControl" >
                        <InputLabel id="geo-label">Geography</InputLabel>
                        <Select
                            labelId="geo-label"
                            label="Geography"
                            id="geography"
                            value={geography}
                            onChange={handleGeoChange}
                        >
                            <MenuItem value={'state'}>State</MenuItem>
                            <MenuItem value={'county'}>County</MenuItem>
                            <MenuItem value={'bbf_region'}>BBF Region</MenuItem>
                            <MenuItem value={'ahs_district'}>AHS District</MenuItem>
                            <MenuItem value={'head_start'}>Head Start Region</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            {/*input column 2*/}
            <div>
                <div>
                    <TextField
                        className="textField"
                        onChange={handleSearchText}
                        id="search-text"
                        label="Search Tables"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /></div>
                <div><Button variant="contained" color="primary" className="searchBtn" onClick={searchTables}>Search Tables</Button></div>
                <div>
                    <TextField
                        id="table"
                        label="Table"
                        select
                        value={table}
                        onChange={handleTableChange}
                        className="textField" >
                        {tables.map((option) => (
                            <MenuItem key={option.table} value={option.table}>
                                {option.table} {option.concept}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <FormControl className="formControl" >
                        <InputLabel shrink htmlFor="var">Variables</InputLabel>
                        <Select
                            native
                            multiple
                            id="variable"
                            label="Variables"
                            value={variable}
                            onChange={handleVariableChange}
                            inputProps={{
                                id: 'var',
                            }}
                        >
                            {variables.map((v) => (
                                <option key={v.variable} value={v.variable}>
                                    {v.label}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div><Button variant="contained" color="primary" className="searchBtn"onClick={getCensusData}>Get Data</Button></div>
            </div>

        </div>
    </div>

        {/*data grid*/}
        <div>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

        </div>

        <div style={{ height: 600, width: '100%', marginTop: '2em' }}>
            <DataGrid
                columns={columns}
                rows={rows}
                components = {{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    </div>

</div>

</div>

    )

}