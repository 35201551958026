import React, {useEffect, useState, useCallback } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { applyConfig } from './ChartHelper';
//import {makeStyles} from "@material-ui/core/styles";
import Loader from "../util/Loader";

const transformSeries = async (barSeries, categories) => {
    console.log('transforming');
    console.log(barSeries);
    let pieSeries = {};
    pieSeries.name = barSeries.name;
    let data = [];
    for(let i = 0; i < categories.length; i++){
        let tmpData = {};
        tmpData.name = categories[i];
        tmpData.y = barSeries.data[i];
        data.push(tmpData);
    }
    pieSeries.data = data;
    console.log('PIE SERIES',pieSeries);
    return pieSeries;


    /*
     [{
                name: 'Capacity',
                data: [{
                  name: 'School based Programs',
                  y: 3852,
                }, {
                  name: 'Private programs',
                  y: 5523
                }]
              }]
    */
}


export default function PieChart() {

    //const classes = useStyles();
    const [options, setOptions] = useState({});
    const { id } = useParams();
    const[open, setOpen] = useState(false);

    //functions to manage the loading indicator
    const showModal = async () => {
      return setOpen(true);
  }
  
  const hideModal = async () => {
      return setOpen(false);
  }

  let data = useCallback( async () => {
    showModal();
      let chartId = {id};
      let baseUri = process.env.NODE_ENV === 'production' ? "api.vtkidsdata.org" :  "api.vtkidsdata.org";
      //let baseUri = 'api.vtkidsdata.org';
      let response = await axios.get(`https://${baseUri}/chart/bar/`+chartId.id);
      console.log(response.data);
      let transformedSeries = await transformSeries(response.data.series[0],response.data.categories);
      //console.log(response.data.metadata.config.title);
      //console.log(response.data.metadata.config.yAxis.type);  
      
      //transform the bar series into a format for pie charts 
      //TODO: probably should create a new 'pie' endpoint in the API


      let options = {
        
          chart: {
              type: 'pie'
          },
          title: {
              text: response.data.metadata.config.title
          },
          series: transformedSeries,
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            }
      };

      options = await applyConfig(options,response.data.metadata.config);
      
      setOptions(options);
      hideModal();
  } , [id])

    useEffect( () => {
        data();
    },[data]);

    return (
        <div>
          <Loader open={open} />
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
}




