import * as React from 'react';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios'

const baseApiUri = process.env.REACT_APP_API_URL;

export default function PortalEditElement(props) {

    console.log('PROPS EDIT',props);

    async function editElement(){

        console.log('ADD PROPS',props);
        console.log('VALUE',value);
        console.log('EDITING: ',props.currentElement);
        console.log('EDITING ID: ', props.currentElementId);

            console.log('edit element');
            if(value !== ''){
                try{
                    let payload = {
                        eltid: props.currentElementId,
                        eltval: value,
                        elttype: props.currentEltType
                    }
                    console.log('PAYLOAD', payload);
                    let endpoint = `${baseApiUri}/portals/editelement`;
                    let elt = await axios.post(endpoint, payload,{withCredentials: true} );
                    console.log(elt);

                } catch(e){}
            }
            
            
        
        
        props.reload();
        props.editClose();


    }

    let initVal = '';
    try{
        initVal = props.currentElement[0].name;
    } catch(e){
        initVal = props.currentElement[0].title;
    }

    const [value, setValue] = React.useState(initVal);
    
  
 //console.log('TAB PROPS', props);
  //let elt = props.currentElement[0];
  //console.log('ELT', elt);

  const handleTextFieldChange = (event) => {
    setValue(event.target.value);
  };


  return (
    <Box sx={{ width: '100%' }}>
     <div style={{margin:'20px'}}  >
        
        { props.currentEltType === 'p' &&
        <TextField  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-topic" label="edit topic" variant="outlined" />
         }
         { props.currentEltType === 't' &&
         <>
         <TextField value={props.currentElement[0].name} style={{marginRight:'5px',marginTop:'5px'}} onChange={handleTextFieldChange}  id="id-topic" label="edit topic" variant="outlined" />
        </>
         }
         { props.currentEltType === 'c' &&
         <>
         <TextField  defaultValue={props.currentElement[0].name}  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-category" label="edit category" variant="outlined" />
         </>
          
          }
           { props.currentEltType === 's' &&
         <>
         <TextField  defaultValue={props.currentElement[0].name}  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-subcategory" label="edit subcategory" variant="outlined" />
         </>
          
          }
           { props.currentEltType === 'i' &&
         <>
         <TextField  defaultValue={props.currentElement[0].title}  style={{marginRight:'5px'}} onChange={handleTextFieldChange}  id="id-indicator" label="edit indicator" variant="outlined" />
         </>
          
          }
         <Button style={{marginTop:'20px'}}variant="contained" onClick={editElement}>Edit</Button>
         
         
      </div>
    </Box>
  );
}