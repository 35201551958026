import React, {useEffect, useState } from 'react'

export default function DashboardIndicators(props){

    const [subCategoryIndicators, setSubCategoryIndicators] = useState([]);
    const [showLinks, setShowLinks] = useState(false);
    const [ subCategoryHeading, setSubCategoryHeading ] = useState("");
    //const [topics, setTopics] = useState([])

    useEffect( () => {
        const getSubCategoryIndicators = async () => {
            console.log('------------>getting subcategory indicators');
            console.log('PROPS',props);
            //console.log(categories);

            let indicatorsArray = [];
            
            for(let i = 0; i < props.indicators.length; i++){
                let indicator = props.indicators[i];
                //console.log('INDICATOR',indicator);
                //create an object and make all the indicator properties lower case for string matches
                let indicatorObj = {};
                for (const [key, value] of Object.entries(indicator)) {
                    indicatorObj[key.toLowerCase()] = value;
                }
                //console.log('SUBCATEGORY',props.subcategory);
                console.log('OBJ', indicatorObj);
 
                if(indicatorObj[props.subcategory.toLowerCase()] === 1){
                    //console.log('ADDING');
                    indicatorsArray.push(indicator);
                }              
            }

            if(indicatorsArray.length > 0){
                setShowLinks(true);
            }
            let pos = props.subcategory.indexOf(':');
            setSubCategoryHeading(props.subcategory.slice(pos+1));
           
            setSubCategoryIndicators(indicatorsArray);
        }
        getSubCategoryIndicators();
    }, [props])



    return (
        <div>
        { showLinks && 
        <div className="subcategory-heading"><b>{subCategoryHeading}</b>
        <ul>
        {subCategoryIndicators.map((i, index) => (
            <li className={"subcategory-link" + (props.indicatorActive === i.id ? '-active' : '')}  
            onClick={() => props.showIndicator(i)} key={index}>{i.title}</li>
        ))}
        </ul>
        </div>
        }
        </div>
    )
}