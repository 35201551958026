import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from "../util/Loader";
import './Chart.css';
import { applyConfig } from './ChartHelper';
//import {makeStyles} from "@material-ui/core/styles";

/* eslint-disable react-hooks/exhaustive-deps */

/*
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

colors: ["#007155","#3b886e","#60a088","#84b8a3","#a7d0bf","#cae9dc","#007155","#3b886e",
                "#60a088","#84b8a3","#a7d0bf","#cae9dc","#e5f5e0","#c7e9c0","#a1d99b","#74c476","#41ab5d",
                "#238b45","#006d2c","#00441b","#ed7330", "#ed8751","#eb9a71","#e6ad90","#ddbfb0","#d1d1d1"],
*/



export default function ColumnChart() {

    Highcharts.setOptions({
        lang: {
                thousandsSep: ','
            }
        }
    );
    
    //const classes = useStyles();
    const [uploadType, setUploadType] = useState('');
    const [options, setOptions] = useState({});
    const { id } = useParams();
    const[open, setOpen] = useState(false);
    //let baseUri = process.env.NODE_ENV === 'production' ? "api.vtkidsdata.org" :  "api.qa.vtkidsdata.org";
    const baseUri = process.env.REACT_APP_API_URL;

    //functions to manage the loading indicator
    const showModal = async () => {
        return setOpen(true);
    }
    
    const hideModal = async () => {
        return setOpen(false);
    }

    let data = useCallback( async () => {
        showModal();
        let chartId = {id};
        let response = await axios.get(`${baseUri}/chart/bar/`+chartId.id);
        //console.log('CHART DATA',response.data);
        //console.log(response.data.metadata.config.yAxis.type);  
        //let defaultTooltip = "'Percent <b>' + this.x +'</b>: <b>' + (this.y).toFixed(0) + '%</b>'";

        //replace null, undefined, or 0 values with something to render the column with '**'
        //console.log('SERIES',response.data.series )
        setUploadType(response.data.metadata?.uploadType);
        console.log(uploadType);
        for(let i = 0; i < response.data.series.length; i++){
            let series = response.data.series[i];
            for(let d = 0; d < series.data.length; d++){
                let data = series.data[d];
                if(data == null || data === 0){
                    console.log('NULL*************');
                    //series.data[d] = .5;
                    series.data[d] = 0;
                }
            }
        }
        let uploadLink = baseUri + '/download/' + response.data.metadata?.uploadType
        console.log('LINK',uploadLink);
        //console.log('TRANSFORMED', response.data.series);

        let options = {

            chart: {
                type: 'column'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            series: response.data.series,
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                }
            },
            xAxis: {
                categories: response.data.categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                //max: 100,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value:.1f}%'
                },
                type: 'percent'
            },
            
        };


         options = await applyConfig(options,response.data.metadata.config, 'ColumnChart');
         let linkStyle = {
            fontSize: '14px'
         };

         if(uploadLink !== null && uploadLink !== ''){
            //let downloadLink = baseUri + '/download/' + uploadType;
            options.title.useHTML = true;
            options.title.style = linkStyle;
            options.title.text = `<a href="${uploadLink}">Download full dataset</a>`;
         }
        
        console.log('OPTIONS', options);
        setOptions(options);
        hideModal();
    } , [id])

    useEffect( () => {
        data();
    },[data]);

    return (
        <div>
            <Loader open={open} />
            
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            
        </div>
    );
}




