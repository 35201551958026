import { lazy } from 'react';


// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import TestChart from "charts/TestChart";
import ColumnChart from "charts/ColumnChart";
import StackedColumnChart from 'charts/StackedColumnChart';
import LineChart from "charts/LineChart";
import PieChart from "charts/PieChart";
import MaterialDataGrid from 'charts/MaterialDataGrid';
import FileUpload from 'util/FileUpload';
//import Vecap from 'vecap/Vecap';
import Dashboard from 'dashboard/Dashboard';
import DashboardAdmin from 'dashboard/DashboardAdmin';

import Portals from 'portals/Portals';
import Portal from 'portals/Portal';
import PortalView from 'portals/PortalView';

//Chart admin 
import ChartAdmin from "charts/ChartAdmin";
import Datasets from "charts/Datasets";
import ChartEdit from "charts/ChartEdit";

//Cenus Application
import Census from "census/Census";
import VkdAssistant from 'openai/VkdAssistant';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
//const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
//const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
//const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
//const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
//const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// ==============================|| MAIN ROUTING ||============================== //

/*
const AppRoutes = () => (
  <Routes >
      <Route index element={<DashboardDefault/>}/>
      <Route path="columnchart/:id" element={<ColumnChart/>}/>
      <Route path="stackedcolumnchart/:id" element={<StackedColumnChart/>}/>
      <Route path="piechart/:id" element={<PieChart/>}/>
      <Route path="linechart/:id" element={<LineChart/>}/>
      <Route path="materialdatagrid/:id" element={<MaterialDataGrid/>}/>
      <Route path="census" element={<Census/>}/>
      <Route path="charts/admin" element={<ChartAdmin/>}/>
      <Route path="charts/administration" element={<ChartsAdmin/>}/>
      <Route path="charts/edit/:id" element={<ChartEdit/>}/>
      <Route path="fileupload/:id" element={<FileUpload/>}/>
      <Route path="oauthcallback" element={<OauthCallback/>}/>
      <Route path="vecap" element={<Vecap/>}/>
      <Route path="dashboard" element={<Dashboard/>}/>
      <Route path="dashboards/admin" element={<DashboardAdmin/>}/>
      <Route path="admin" element={<Admin/>}/>
      <Route path="test" element={<TestChart />}/>
  </Routes>

);
*/

const MainRoutes = [
{
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/admin',
            element: <DashboardDefault />
        },
        {
            path: '/charts/admin',
            element: <ChartAdmin />
        },
        {
            path: '/fileupload/:id',
            element: <FileUpload />
        },
        {
            path: '/dashboards/admin',
            element: <DashboardAdmin />
        },
        {
            path: 'charts/edit/:id',
            element: <ChartEdit />
        },
        {
            path: 'datasets',
            element: <Datasets />
        },
        {
            path: 'census',
            element: <Census />
        },
        {
            path: '/portals',
            element: <Portals />
        },
        {
            path: '/portal/:id',
            element: <Portal />
        }
    ]
},
{
    path: '/columnchart/:id',
    element: <ColumnChart />
},{
    path: '/dashboard',
    element: <Dashboard />
},{
    path: '/portalview/:id',
    element: <PortalView />
},{
    path: '/test',
    element: <TestChart />
},{
    path: '/vkd-assistant',
    element: <VkdAssistant />
},
{
    path: 'stackedcolumnchart/:id',
    element: <StackedColumnChart/>
},
{
     path: 'piechart/:id',
    element: <PieChart/>
},
{
    path: 'linechart/:id',
    element: <LineChart/>
},
{
    path: 'materialdatagrid/:id',
    element: <MaterialDataGrid/>
}
];

export default MainRoutes;
