import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
//import { useDemoData } from '@mui/x-data-grid-generator';
import { useParams } from 'react-router-dom';
import axios from "axios";
import {useEffect, useState, useCallback } from "react";
import Loader from "../util/Loader";

const baseUri = process.env.REACT_APP_API_URL;

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

//utility functions to translate Material UI Table columns and rows to the structure required for DataGrid
function prepareColumns(columns){
    let cols = [];
    for(let i = 0; i < columns.length; i++ ){
        let column = columns[i];
        column.field = column.id;
        column.headerName = column.label;
        column.flex = 1;
        cols.push(column);
    }
    return cols;
}

export default function MaterialDataGrid() {

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const { id } = useParams();
    const[open, setOpen] = useState(false);
    
    //functions to manage the loading indicator
    const showModal = async () => {
        return setOpen(true);
    }
    
    const hideModal = async () => {
        return setOpen(false);
    }

    let data = useCallback( async () => {
        showModal();
        let tableId = {id};
       // let baseUri = process.env.NODE_ENV === 'production' ? "api.vtkidsdata.org" :  "api.qa.vtkidsdata.org";
        let response = await axios.get(`${baseUri}/table/table/`+tableId.id);
        console.log(response.data);
        let columns = response.data.columns;
        console.log('DATA COLS',response.data.columns );
        columns = prepareColumns(columns);
        console.log('COLS',columns);
        let rows = response.data.rows;
        console.log('ROWS', rows);
        setRows(rows);
        setColumns(columns);
        hideModal();
    } , [id])

    useEffect( () => {
        data();
    },[data]);

    return (
        <div style={{ height: 300, width: '100%' }}>
            <Loader open={open} />
            <DataGrid
                getRowId={(row) => row.id}
                columns={columns}
                rows={rows}
                components = {{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false
                      },
                    },
                  }}
            />
        </div>
    );
}