import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { applyConfig } from './ChartHelper';

import Loader from "../util/Loader";

//import {makeStyles} from "@material-ui/core/styles";
/* eslint-disable react-hooks/exhaustive-deps */

export default function StackedColumnChart() {

    Highcharts.setOptions({
        lang: {
                thousandsSep: ','
            }
        }
    );
    
    //const classes = useStyles();
    const [uploadType, setUploadType] = useState('');
    const [options, setOptions] = useState({});
    const { id } = useParams();
    const[open, setOpen] = useState(false);
    const baseUri = process.env.REACT_APP_API_URL;

      //functions to manage the loading indicator
        const showModal = async () => {
            return setOpen(true);
        }
        
        const hideModal = async () => {
            return setOpen(false);
        }

        let data = useCallback (async () => {
            showModal();
            let chartId = {id};
            //let baseUri = process.env.NODE_ENV === 'production' ? "api.vtkidsdata.org" :  "api.qa.vtkidsdata.org";
            let response = await axios.get(`${baseUri}/chart/bar/`+chartId.id);
            console.log(response.data);
            console.log(response.data.metadata.config.yAxis.type);  
            setUploadType(response.data.metadata?.uploadType);
            console.log(uploadType);
            let uploadLink = baseUri + '/download/' + response.data.metadata?.uploadType
            
            let options = {
    
                chart: {
                    type: 'column'
                },
                title: {
                    text: response.data.metadata.config.title
                },
                subtitle: {
                    text: response.data.metadata.config.subtitle
                },
                series: response.data.series,
                //series: series,
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        pointPadding: 0.2,
                        borderWidth: 0
                    },
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.y:.1f}%'
                        }
                    }
                },
                xAxis: {
                    categories: response.data.categories,
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    //max: 100,
                    title: {
                        text: response.data.metadata.config.yAxis.title
                    },
                    labels: {
                        format: '{value:.0f}%'
                    },
                }
            };
    
    
             options = await applyConfig(options,response.data.metadata.config);
             let linkStyle = {
                fontSize: '14px'
             };
    
             if(uploadLink !== null && uploadLink !== ''){
                //let downloadLink = baseUri + '/download/' + uploadType;
                options.title.useHTML = true;
                options.title.style = linkStyle;
                options.title.text = `<a href="${uploadLink}">Download full dataset</a>`;
             }
            
            setOptions(options);
            hideModal();
    
        }, [id])

    useEffect( () => {
        data();
    },[data]);



    return (
        <div>
            <Loader open={open} />
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
           
            
        </div>
    );
}




