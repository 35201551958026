
import React, { useEffect } from 'react';

export default function TestChart() {


    useEffect( () => {
       
    },[]);

    return (
        <div>
           
            <div>TEST CHART</div>
            
        </div>
    );
}