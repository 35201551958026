import React, {useState} from 'react';
import axios from 'axios';
import AssistantLoader from "../util/AssistantLoader";
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'

import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';

import './VkdAssistant.css';

export default function VkdAssistant(){



const baseApiUri = process.env.REACT_APP_API_URL;
const [isLoading, setIsLoading] = useState(false);
const [isVisible, setIsVisible] = useState(true);
const [inputText, setInputText] = useState("");
const [openAiResponse, setOpenAiResponse] = useState("");
const [openAiQuestion, setOpenAiQuestion] = useState("");
const[open, setOpen] = useState(false);


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

const handleInputChange = (event) => {
    setInputText(event.target.value);
};


const handleButtonClick = async () => {

    //setIsLoading(true);
    //setOpen(true);

    let id =  (new Date()).getTime();
    console.log(id);
    //return false;
    console.log(inputText);
    let question = inputText;
    setOpenAiQuestion(question);
    console.log('QUESTION', question);
    let message = '';
    setInputText('');

    if(inputText != null && inputText !== ''){
        let endpoint = `${baseApiUri}/ai/completion`;
        let payload = {
            "key": "09848734-8745-afrt-8745-8745873487",
            "id": id,
            "sortKey": 0,
            "query": question,
            "stream": true
        }
        let response = await axios.post(endpoint, payload, {});
        console.log(response.data);
        let hasMore = true;
        setIsLoading(false);
        setIsVisible(true);
        setOpen(false);
        setOpenAiResponse('One moment while I analyze data from VermontKidsData....');
        while(hasMore){
            console.log('polling completion');
            try{
                let endpoint =  `${baseApiUri}/ai/completion/${id}/0`;
                let completion = await axios.get(endpoint);
                console.log(completion.data);
                try{
                    let status = completion.data.response.status;
                    message = completion.data.response.message;
                    console.log('MESSAGE', message);
                    if(message !== '' && message != null){
                        setOpenAiResponse(message);
                        
                    }
                    if(status === 'success'){
                        hasMore = false;
                        
                    }
                } catch(e){
                    hasMore = false;
                }
                
                
                await sleep(100);
            } catch(e){
                hasMore = false;
                //setOpen(false);
            }
        }
        
    } else {
        message = 'please enter a question';
    }

    console.log(message);
    //setOpenAiResponse(message);
    //setIsLoading(false);
    //setIsVisible(true);
    //setOpen(false);

};



return (
    <div>
        <AssistantLoader open={open} />
        <center>
        <div className="react-chatbot-kit-chat-container">

        <div className="react-chatbot-kit-chat-header">VermontKidsData AI Assistant</div>

        {isVisible && (
        <div className="react-chatbot-kit-chat-message-container"
          
        >
          <h2 style={{ fontFamily: 'DM Sans, sans-serif' }}>{openAiQuestion}</h2>
          <p style={{ fontFamily: 'DM Sans, sans-serif', color: "#333", fontSize: "16px", lineHeight: 1.4, textAlign: 'left' }}>
            {openAiResponse}
          </p>
         
        </div>
      )}

      <input
        type="text"
        placeholder="Ask a question about Vermont's young children..."
        value={inputText}
        onChange={handleInputChange}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            handleButtonClick();
          }
        }}
        style={{
          fontFamily: 'DM Sans, sans-serif',
          width: "80%",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          marginLeft: "0px",
          
        }}
      />
      <button
        onClick={handleButtonClick}
        disabled={isLoading}
        className={isLoading ? "flipping-button" : ""}
        style={{
          alignItems: "center",
          animation: isLoading ? "flip 0.8s ease-in-out" : "none",
          marginTop: "10px",
          padding: "8px 12px",
          fontSize: "16px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#007bff",
          color: "#fff",
          cursor: "pointer",
          marginLeft: "15px",
          
        }}
      >
        &rarr;
      </button>

      </div>
      </center>

      {isLoading && <p style={{ fontFamily: 'DM Sans, sans-serif', marginLeft: '12px' }}></p>}
  


<div style={{ width: '100%', display: 'none'}}>
      <center><Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        headerText='VermontKidsData AI Assistant'
        placeholderText='Type your question here'
      /></center>
    </div>

      </div>
)
        }