import { S3, S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import axios from 'axios';
import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { isAuthenticated } from "./Auth";
import Loader from "./Loader";

import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Home from "Home";

const bucket = process.env.REACT_APP_UPLOAD_BUCKET;
const region = process.env.REACT_APP_REGION;

function CustomToolbar() {
  return (
      <GridToolbarContainer>
          <GridToolbarExport />
      </GridToolbarContainer>
  );
}


async function prepareColumns(headers){

  console.log('-----------> preparing columns', headers);

  let cols = [];
   
  for(let i = 0; i < headers.length; i++ ){
      let column = headers[i];
      let tmpCol = {};
      tmpCol.field = column;
      tmpCol.headerName = column;
      cols.push(tmpCol);
  }
  let id = { field: 'id', headerName: 'ID'};
  cols.push(id);
  
  return cols;
}

//var csv is the CSV file with headers
const csvJSON = async (csv) => {

  console.log('GETTING CSV', csv);
  var lines=csv.split("\n");
  var result = [];
  var headers=lines[0].split(",");
  //result.push(headers);
  for(var i=1;i<lines.length;i++){

      var obj = {};
      obj['id'] = i;
      var currentline=lines[i].split(",");

      for(var j=0;j<headers.length;j++){
          obj[headers[j].toString()] = currentline[j];
      }

      result.push(obj);

  }
  let retVal = {};
  retVal.headers = headers;
  retVal.rows = result;
  console.log('RESULT', result);
  return retVal;
  //setRows(result);
  //return result; //JavaScript object
  //return JSON.stringify(result); //JSON
}

const FileUpload = () => {

  const baseUri = process.env.REACT_APP_API_URL;
  const credsUrl = `${baseUri}/credentials`;
  const hostedUi = process.env.REACT_APP_HOSTED_UI;

  //functions to manage the loading indicator
const showModal = async () => {
  return setOpen(true);
}

const hideModal = async () => {
  return setOpen(false);
}

const upload = async (fileToUpload) => {

    showModal();

    var file = fileToUpload.target.files[0];
    console.log(file);

    //let type = values.type;
    console.log('UPLOAD TYPE', type);
    //return false;

    let endpoint = `${baseUri}/queries/`;
    console.log(endpoint);
    try{
      let queries = await axios.get(endpoint,{ withCredentials: true });
      console.log(queries);
    } catch(e){
      console.log(e);
    }
    

    //let creds = await getCognitoCreds();
    //return true;

    //get temporary credentials from the Identity Pool
    let creds = await axios.get(credsUrl, { withCredentials: true });
    console.log(creds)

    //create a unique ID 
    const identifier = uuidv4();
    const target = { Bucket:bucket, Key:`${identifier}.csv`, Body:file };
    console.log('UPLOADING', type, identifier);
    let tags = [
      {
        Key: 'type',
        Value: type 
      },
      {
        Key: 'identifier',
        Value: identifier
      },
    ];
    console.log(tags);

    try {
        
        const parallelUploads3 = new Upload({
            client: new S3({
                region: region,
                credentials: creds.data
            }) || new S3Client({}),
            tags: [
              {
                Key: 'type',
                Value: type 
              },
              {
                Key: 'identifier',
                Value: uuidv4() 
              },
            ],
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
            console.log('UPLOAD PROGRESS',progress);
        });

        parallelUploads3.done();
        hideModal();
        

    } catch (e) {
        console.log(e);
    }   
}



  const initialValues = {
    type: "",
    };

   //set the values of inputs to the constant
	  const [values, setValues] = useState(initialValues);
    const[type, setType] = useState('');
    const[title, setTitle] = useState('');
    const[user, setUser] = useState(false);
    const { id } = useParams();
    const[open, setOpen] = useState(false);
    const[auth, setAuth] = useState(false);
    const[rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const handleInputChange = (e) => {
      //console.log(e.target);
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
      };


    let initUpload = useCallback( async () => {

      showModal();

      console.log('DATASET ID', id);
      let isAuth = await isAuthenticated();
      setAuth(isAuth);
      console.log('AUTH',isAuth);

      //let credsUrl = '/credentials';
      //console.log(credsUrl);
      //let endpoint = `${baseUri}/queries/`; 
      let utendpoint = `${baseUri}/table/table/upload_types`;     
      //const jsonArray= await csv().fromFile(tableEndpoint);
      //console.log(jsonArray);
      let uploadType = '';
      try{
        //let queries = await axios.get(endpoint,{ withCredentials: true });
        let uploadTypes = await axios.get(utendpoint);
        for(let i = 0; i < uploadTypes.data.rows.length; i++){
          let t = uploadTypes.data.rows[i];
          //console.log('QUERY ID', query.id);
          //console.log('ID', id);
          if(t.id.toString() === id.toString()){
            console.log('CURRENT UPLOAD TYPE', t);
            setTitle(t.name);
            console.log(t.type);
            uploadType = t.type;
            setType(uploadType);
          }
        }
        //console.log('QUERIES',queries);
        /*
        for(let i = 0; i < queries.data.rows.length; i++){
          let query = queries.data.rows[i];
          //console.log('QUERY ID', query.id);
          //console.log('ID', id);
          if(query.id.toString() === id.toString()){
            console.log('CURRENT QUERY', query);
            let meta = JSON.parse(query.metadata);
            setTitle(meta.title);
            console.log(query.uploadType);
            uploadType = query.uploadType;
            setType(query.uploadType);
          }
        }
        */
        //console.log(tableData);
      } catch(e){
        console.log(e);
      }
      let tableEndpoint =  `${baseUri}/download/${uploadType}?limit=10`; 
      console.log(tableEndpoint);
      try{

        let tableData = await axios.get(tableEndpoint);
        console.log('TABLE DATA',tableData);
        let file = tableData.data;
        let csv = await csvJSON(file);
        console.log(file);
        console.log('CSV',csv);
        let gridCols = await prepareColumns(csv.headers);
        console.log('GRID Cols', gridCols);
        console.log('CSV ROWS', csv.rows);
        setRows(csv.rows);
        //let columns = await prepareColumns(csv.headers);
        setColumns(gridCols);
        hideModal();
        
      } catch(e){
        console.log(e.message);
      }
      //see if we can get credentials... this will determine if we're logged in
      try{
        let creds = await axios.get(credsUrl,{ withCredentials: true });
        console.log('CREDENTIALS', creds);
        console.log(user);
        setUser(true);
      } catch(e){
        console.log(e);
        setUser(false);
      }
      //let creds = await axios.get(credsUrl, { withCredentials: true });
      //console.log(creds)
      hideModal();
    
      } ,[baseUri, credsUrl, id, setUser,user])

    useEffect( () => {
        initUpload();
      },[initUpload]);

    return (
  
     <ul>
        {isAuthenticated ? (
          <div >
          <Container>
          <Loader open={open} />
          <h3>Upload Data</h3>
          <div>{title}</div>

              { !auth && (
              <div><a href={hostedUi}>login</a></div>
            ) }

            <div>
              <TextField 
              id="type-key" 
              label="Type" 
              variant="outlined" 
              value={type} 
              name="type"
              onChange={handleInputChange} 
              className="App-textarea"
              />
          </div>

          <div><input type="file" onChange={upload}  /></div>
          <div style={{ paddingTop: 20 }}>
          <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>  
          <DataGrid
                columns={columns}
                rows={rows}
                components = {{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false
                      },
                    },
                  }}
            />
            </Box>
            </div>
            </Container>
          </div>
        ) : (
          <Home />
        )
       
        }
 </ul>
       
    )
};

export default FileUpload;

/*
  const getCognitoCreds = async () => {

    const creds = new FromCognitoIdentityPool({
      client: new CognitoIdentityClient({
        region,
      }),
      identityPoolId,
      logins: {
        [identityProvider]: idToken,
      },
    })
  
    const credentials = await creds()
    console.log(credentials);

    return credentials;

  }
  */