import React, {useEffect, useState, useCallback } from 'react'
import {  useNavigate } from "react-router-dom";
import axios from 'axios'
import '../Admin.css';
//import { useParams } from 'react-router-dom';
//Material UI components

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//import Box from '@mui/material/Box';
//import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
//icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
//import UploadIcon from '@mui/icons-material/Upload';
import Loader from "../util/Loader";

/*
GET /dataset/backups/{dataset} - list backups for a dataset
GET /dataset/backups/{dataset}/{version} - download a backup version (might want to use it for "are you sure?" purposes)
POST /dataset/backups/{dataset}/{version}/revert - revert a dataset to a backup version
*/

function SimpleDialog(props) {
const { onClose, selectedValue, open } = props;

const handleClose = () => {
    onClose(selectedValue);
};

const handleConfirmClick = (value) => {
    onClose(value);
};

return (
    <Dialog onClose={handleClose} open={open}>
        <div className="admin-dialog">
        <DialogTitle>Delete Report</DialogTitle>
        <div>The report you selected is about to be permanently deleted.  Do you wish to continue?</div>
        <Button onClick={() => handleConfirmClick('yes')}>Yes</Button>  
        <Button onClick={() => handleConfirmClick('no')}>No</Button>  
        </div>      
    </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

async function createData(
    id,
    name,
    title,
    uploadType
  ) {
    return { id, name, title, uploadType };
  }

const baseUri = process.env.REACT_APP_API_URL;
  

export default function ChartAdmin() {

    const [rows, setRows] = useState([]);
    const[loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('no');

    //functions to manage the loading indicator
    const showModal = async () => {
        return setLoading(true);
    }
    
    const hideModal = async () => {
        return setLoading(false);
    }

    const handleClickOpen = (chartId) => {
        setOpen(true);
        setSelectedValue(chartId)
        console.log('OPEN CHART ID',chartId);
    };    
    const handleClose = (value) => {
        setOpen(false);
        let chartId = {selectedValue};
        console.log(chartId['selectedValue']);
        if(value === 'yes'){
            deleteChart(chartId.selectedValue);
        }
        console.log(value);
    };

    const navigate = useNavigate();

    const createChart = async() =>{
        navigate(`/charts/edit/create`);
    }

    let getCharts = useCallback( async () => {
        //console.log(queries);
        showModal();
        let endpoint = `${baseUri}/queries/`;
        //console.log(endpoint);

        let rows = [];
        try{

            /*
            let uploadTypes = await axios.get(`${baseUri}/table/table/upload_types`);
            console.log('UPLOAD TYPES',uploadTypes);

            let uploadTypesArray = [];
            for(let i  = 0; i < uploadTypes.data.rows.length; i++){
                let t = uploadTypes.data.rows[i];
                uploadTypesArray.push(t.type);
            }
            */

            let queries = await axios.get(endpoint,{withCredentials: true});
            console.log('QUERIES',queries);
            //let q = JSON.parse(queries);           
            for(let i  = 0; i < queries.data.rows.length; i++){
                let query = queries.data.rows[i];
                //console.log(JSON.parse(query.metadata));
                try{
                    let metaData = JSON.parse(query.metadata);
                    //console.log(metaData);
                    let title = metaData != null ? metaData.title : 'NO TITLE';
                    //console.log(title);
                    //if(uploadTypesArray.includes(query.uploadType)){
                        rows.push( await createData(query.id, query.name, title, query.uploadType) );
                    //}
                    
                    setLoading(false);
                } catch(e){
                    //rows.push( await createData(query.id, 'NO TITLE') );
                    setLoading(false);
                }
                
            }
        } catch(e){
            console.log('ERROR LOADING CHARTS', e.message);
            //return redirect("/");
        }


        //console.log(rows);
        setRows(rows);
        hideModal();
    } , [])


    useEffect( () => {
        getCharts();
        
    },[getCharts]);

    let deleteChart = async (id) => {

        console.log(id);
        let endpoint = `${baseUri}/queries/${id}`;
        console.log(endpoint);
        
        let res = await axios.delete(endpoint,{withCredentials: true});
        console.log(res.data);
        let updatedRows = [];
        for(let i  = 0; i < rows.length; i++){
            let row = rows[i];
            if(row.id !== id ){
                updatedRows.push(row);
            }
        }
        setRows(updatedRows);
       

    }

    let  columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'title',
            headerName: 'Title',
            flex: 3,
            editable: true,
          },
        {
          field: 'name',
          headerName: 'Name',
          flex: 3,
          editable: true,
        },
        {
            field: 'edit',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <EditOutlinedIcon className="App-icon-spacing App-icon-cursor" onClick={() => navigate(`/charts/edit/${row.id}`)} />
              </strong>
            ),
          }
          ,
        {
            field: 'delete',
            headerName: '',
            renderCell: ({ row }) => (
              <strong>
                 <DeleteOutlineIcon className="App-icon-spacing App-icon-cursor" onClick={() => handleClickOpen(`${row.id}`)} />
              </strong>
            ),
          }
         
      ];

    return (
        <div>
            <Loader open={loading} />
            <Container>
            <h3>Charts/Visualizations</h3>
            <div>
            <Box sx={{ height: 500, width: '100%', backgroundColor: '#ffffff' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
                </Box>
            </div>

            <div>
                <br></br>
            <Button variant="contained" onClick={createChart}>New Chart</Button>
            </div>

            </Container>
            <SimpleDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
        />
        </div>
    );


}