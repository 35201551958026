import React, {useState}  from 'react';
import axios from 'axios';
import AssistantLoader from "../../util/AssistantLoader";

const baseApiUri = process.env.REACT_APP_API_URL;

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const[open, setOpen] = useState(false);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const handleQuestion = async (message) => {

    console.log('ACTION PROVIDER');
    console.log(message);

    let resp = '';
    let id =  (new Date()).getTime();
    if(message != null && message !== ''){
      setOpen(true);
      let endpoint = `${baseApiUri}/ai/completion`;
      let payload = {
          "key": "09848734-8745-afrt-8745-8745873487",
          "id": id,
          "sortKey": 0,
          "query": message
      }
      let response = await axios.post(endpoint, payload, {});
      console.log(response.data);
      let hasMore = true;
      
      while(hasMore){
          console.log('polling completion');
          try{
              let endpoint =  `${baseApiUri}/ai/completion/${id}/0`;
              let completion = await axios.get(endpoint);
              console.log(completion.data);
              try{
                  let status = completion.data.response.status;
                  resp = completion.data.response.message;
                  if(status === 'success'){
                      hasMore = false;
                      
                  }
                  if(status === 'failed'){
                    hasMore = false;
                    resp = 'There has been a temporary issue accessing the data.  Please enter your question again'
                  }
              } catch(e){
                  hasMore = false;
              }
              
              await sleep(2000);
          } catch(e){
              hasMore = false;
          }
      }
      
  } else {
      resp = 'please enter a question';
  }
    setOpen(false);
    const botMessage = createChatBotMessage(resp);

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

  };

  return (
    <div>
      <AssistantLoader open={open} />
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleQuestion
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;