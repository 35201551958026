import React, {useEffect, useState } from 'react'

export default function PortalIndicators(props){

    const [subCategoryIndicators, setSubCategoryIndicators] = useState([]);
    const [showLinks, setShowLinks] = useState(false);
    const [ subCategoryHeading, setSubCategoryHeading ] = useState("");
    //const [topics, setTopics] = useState([])

    useEffect( () => {
        const getSubCategoryIndicators = async () => {
            console.log('------------>getting subcategory indicators');
            console.log('INDICATOR PROPS',props);
            //console.log(categories);

            let indicatorsArray = [];
            
            for(let i = 0; i < props.indicators.length; i++){
                let indicator = props.indicators[i];
                //console.log('INDICATOR',indicator);
                //create an object and make all the indicator properties lower case for string matches
                let indicatorObj = {};
                for (const [key, value] of Object.entries(indicator)) {
                    indicatorObj[key.toLowerCase()] = value;
                }
                //console.log('SUBCATEGORY',props.subcategory);
                //console.log('OBJ', indicatorObj);
 
                
                if(indicator.topic === props.topic && indicator.category === props.category && indicator.subcategory === props.subcategory){
                    //console.log('ADDING');
                    indicatorsArray.push(indicator);
                } 
                             
            }

            if(indicatorsArray.length > 0){
                setShowLinks(true);
            }
            //let pos = props.subcategory.indexOf(':');
            setSubCategoryHeading(props.name);
           
            setSubCategoryIndicators(indicatorsArray);
        }
        getSubCategoryIndicators();
    }, [props])



    return (
        <div>
        { showLinks && 
        <div className="subcategory-heading"><b>{subCategoryHeading}</b>
        <ul>
        {subCategoryIndicators.map((i, index) => (
            <li className={"subcategory-link" + (props.indicatorActive === i.id ? '-active' : '')}  
            onClick={() => props.showIndicator(i)} key={index}>{i.title.split("~")[0]}</li>
        ))}
        </ul>
        </div>
        }
        </div>
    )
}